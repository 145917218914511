import React from "react";

import AppLayout from "../../../components/shared/AppLayout";

const PageTermsAndConditions04 = () => {
    return (
        <AppLayout hasHeader={true} isBackBtn={true} hasFooter={true}>
            <div className="app-page page-terms-and-conditions">

                <div className="terms-and-conditions">

                    <div className="container">

                        <p className="terms-and-conditions__title">
                            <strong>保特保險經紀人網路投保聲明事項</strong>
                        </p>

                        <div className="terms-and-conditions__wrapper">
                            <p>網路投保註冊之身分證字號本人（以下稱要保人），於保特保險經紀人公司（以下稱本公司或保特保經），以網際網路之方式，透過保特保經網頁與保險公司締結或洽訂保險契約時，要保人同意下列條款，絕無異議：</p>
                            <p>一. 網路投保系統係指本公司提供之投保網頁、行動 APP 程式或其他經由網際網路連線進行投保程序之作業系統。</p>
                            <p>二. 要保人須完成註冊程序後，始得使用網路投保系統。</p>
                            <p>三. 要保人充分了解，個人密碼為對要保人投保之認證，經核對密碼無誤時，即視為要保人親自投保；該密碼要保人承諾應審慎保管，若因外洩致帳戶遭人冒用，無論要保人是否知情，其所生之一切責任，概由要保人自行負責。</p>
                            <p>四. 要保人同意並了解透過網際網路等電子式交易型態投保，因電子訊息的傳送過程必須花費一些時間，並非即時完成，故不能保證投保時間與要保人預期相符。</p>
                            <p>五. 要保人同意並了解使用網路投保系統時，可能面對以下風險：如斷線、斷電、網路壅塞或其他因素等造成傳輸之阻礙，致投保資訊無法傳送、接收或時間延遲。另外其他可能導致網路投保系統無法正常使用之原因，如網路提供業者之線路穩定性、使用者操作不當、斷電及天災等不可抗力因素，係非本公司可控制範圍。假如網路投保系統在任何時候無法使用或有所延遲，要保人同意使用其它管道，例如電話或親臨本公司營業處所等方式確認。</p>
                            <p>六. 要保人同意並了解如有下列情形之一，本公司得不執行任何接收之電子訊息：1. 要保人傳送之電子訊息無法完整辨識其內容。 2. 有相當理由懷疑電子訊息之真實性或所指定事項之 正確性。 3.依據電子訊息執行或處理業務，將違反相關法律或命令。 4. 投保內容有道德 風險之虞。上述所稱電子訊息係指委託人以網際網路之網路投保方式所傳送之相關訊息</p>
                            <p>七. 經由網路投保系統之投保資料需經承保之保險公司核保單位同意或確定保險費收訖後，始生 效力。</p>
                            <p>八. 要保人經完成身分驗證作業而取得進行網路投保作業之帳號密碼者，如於申請完成後五年之期間內並未再經由本公司與保險公司洽訂保險契約或辦理保險服務者（不以透過網路進行為限），要保人非經重新完成前述身分驗證，不得再利用帳號密碼進行網路投保作業或網路保 險服務。</p>
                            <p>本會員已詳細閱讀並充分瞭解上述網路投保聲明書內容申請網路投保之注意事項、可能產生之風險及相關使用約定，爰同意以申請本網路進行網路投保事宜，特此聲明。</p>
                        </div>

                    </div>

                </div>

            </div>
        </AppLayout>
    );
};

export default PageTermsAndConditions04;
